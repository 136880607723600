import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import LatestWorkLayout from "../../templates/latestWork/LatestWork.component.js";
export const _frontmatter = {
  "title": "Web app for Akari Center",
  "path": "/latest-works/akariapp",
  "date": "2020-09-17T00:00:00.000Z",
  "description": "This is a web app developed for Akari Skills Training Center For Children With Special Needs. It is called CWD Examination Toolkit (or CET), with the main purpose of examining the growth of children under 7 years old to detect areas with slowness/difficulty based on PEP-3 (the Psychoeducational Profile, Third Edition). The app allows CRUD operations and export of report in PDF/CSV, and is built with React, PouchDB and Material UI.",
  "img": "akariapp.png",
  "imgAlt": "Image for Akari App",
  "url": "https://cwdexam.netlify.app"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LatestWorkLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>A web app for managing Children with disability (CWD)</h1>
    <p>{props.pageContext.frontmatter.description}</p>
    <h2>Main features of the app:</h2>
    <ul>
  <li>CRUD operations</li>
  <li>Save/Load data files from local disk</li>
  <li>Export PDF/CSV</li>
  <li>Can work offline</li>
  <li>Responsive design</li>
  <li>Hosted on Vercel</li>
    </ul>
    <h2>Main incorporated libraries:</h2>
    <ul>
  <li>React</li>
  <li>Material UI</li>
  <li>React-hook-form</li>
  <li>Streamsaver</li>
  <li>Memorystream</li>
  <li>Papaparse</li>
  <li>PDFMake</li>
  <li>PouchDB</li>
  <li>Little-state-machine</li>
    </ul>
    <p>{`A manual (in Vietnamese) and sample data file for demo can be downloaded `}<a href="https://github.com/dreamofi/portfolioPageResoures/blob/master/CET-20201029T142916Z-001.zip" target="_blank"><strong>{`here`}</strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      